<template>
    <section class="wrapper profile" :class="{'participant-container' : !deputy}">
        <CommonBlock title="" @clickBack="clickBack" :visibleBackBtn="true" :cancelBackBtnEventPassed="true">
            <div class="description">
                Тут зібрані усі твої досягнення. Але не зупиняйся, покращуй свої результати! <br> <br>
                <ul>
                    <li>Заробляй бджілки за активну участь у партійних заходах. Як? 5 записів – бронза, 15 – срібло, 35 записів – золото, Більше 50 – платина. Система рахує вашу активність щомісяця, не втрачай своїх бджілок.  </li>
                    <li>Залучай якомога більше друзів до лав проєвропейської, проукраїнської партії.</li>
                    <li>Пиши, подавай, реалізуй. Будь серед найкращих! </li>
                    <li>Висвітлюй події та свою позицію с популярними хештегами партії.</li>
                </ul>
            </div>
            <div class="img">
                <img src="@/assets/images/5_achievements.svg" alt="cabinet">
            </div>
        </CommonBlock>

        <article class="content custom-scroll">
            <form class="registartion-form achievement-form" @submit.prevent="submitHandler">

                <div class="tabs-container" v-if="!deputy">
                    <router-link to="/encouragement/1">
                        <div class="tab-item">Система заохочень</div>
                    </router-link>
                    <router-link to="/add-friends">
                        <div class="tab-item">Запросити друзів</div>
                    </router-link>
                    <button class="achievement-btn blue-btn" type="button">
                        <div class="tab-item">Мої досягнення</div>
                    </button>
                </div>

                <input type="hidden" name="gender" v-model="user.gender">
                <input type="hidden" name="is_deputy" v-model="user.is_deputy">
                <input type="hidden" name="is_party_member" v-model="user.is_party_member">
                
                <Achievement v-if="!deputy" :awards="user.awards"/>
            </form>
            
        </article>
    </section>
</template>

<script>
import CustomButton from "../components/CustomButton";
import CommonBlock from "../components/CommonBlock";
import InputComponent from "../components/ui/InputComponent";
import CustomSelect from '../components/CustomSelect'
import UserService from "../services/UserService";
import vSelect from "vue-select";
import CustomSelectV2 from "../components/ui/CustomSelectV2";
import CouncilService from "../services/CouncilService";
import RegionService from "../services/RegionService";
import DatepickerComponent from "../components/ui/DatepickerComponent";
import Achievement from "../components/Achievement";

export default {
    name: 'cabinet',
    data() {
        return {
            disabled: false,
            requireFields: [],
            user: {},
            errors: {},
            previewImage: null,
            image: null,
            councils: [],
            regions: [],
            areas: [],
            hovering: false,
            deputy: this.$store.getters.identity.is_deputy,
            activityList: [],
            activityId: null,
            partyOrganizationList: [],
            organizationId: null,
            hidePhone: 0,
            achievement: false,
        }
    },
    components: {
        CustomButton,
        CommonBlock,
        InputComponent,
        DatepickerComponent,
        vSelect,
        CustomSelectV2,
        CustomSelect,
        Achievement,
    },
    created() {
        this.user = Object.assign({}, this.$store.getters.identity);

        this.organizationId = this.party_organization_id
        this.activityId = this.job_type_id

        this.user.cadence = this.user.cadence || 0;
        this.setCouncils();
        this.setRegions();
        this.getActivityList();
        this.getOrganizationList();

        if(this.user.area_id) {
            this.setAreas(true);
        }
    },
    watch:{
      hidePhone(){
          if (this.hidePhone){
              this.hidePhone = 1
          } else {
              this.hidePhone = 0
          }
      }
    },
    methods: {
        clickBack() {
            this.$router.go(-1)
        },
        async setCouncils() {
            let councils = await CouncilService.get();
            this.councils = councils.map(council => {
                return {
                    value: +council['key'],
                    label: council['value']
                };
            });
        },
        isDeputy() {
            return this.$store.getters.identity.rada_id && this.$store.getters.identity.is_deputy;
        },
        async setRegions() {
            let regions = await RegionService.get([1]);
            this.regions = regions.results.map(region => {
                return {
                    value: +region['id'],
                    label: region['text']
                };
            });
        },
        async setAreas(cancelResetArea=false) {
            if(cancelResetArea !== true) {
                this.user.area_id = null;
            }
            let areas = await RegionService.get([3,4,5,6], this.user.region_id);
            this.areas = areas.results.map(area => {
                return {
                    value: +area['id'],
                    label: area['text']
                };
            });
        },
        cancelSubmit(e) {
            this.user = Object.assign({}, this.$store.getters.identity);
        },
        async submitHandler(e) {
            let resp = await UserService.update(new FormData(e.target)).catch(err => {
                if(err.data) {
                    this.errors = err.data;
                }
                setTimeout(() => {
                    let errorLabels = document.getElementsByClassName('error-label');

                    if(errorLabels[0]) {
                        errorLabels[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    }
                }, 100);
            });

            if(resp && resp.status === 'success') {
                this.$notify({
                    text: 'Успішно збережено!',
                    type: 'success',
                });
                this.errors = {};
            }
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();

            reader.onload = function (e) {
                this.user.photo = e.target.result;
            }.bind(this);
            reader.readAsDataURL(file);
        },
        async getActivityList(){
            let response = await UserService.getActivityList()
            response.forEach(item => {
                this.activityList.push(
                    {"label": item.name, 'value': item.id}
                )
            })
        },
        async getOrganizationList(){
            let response = await UserService.getPartyOrganization()
            response.forEach(item => {
                this.partyOrganizationList.push(
                    {"label": item.name, 'value': item.id}
                )
            })
        }
    }
}
</script>


<style lang="less" scoped>
.control-btn{
    min-width: 220px;
    margin: 0 0 0 auto!important;
    @media all and(max-width: 600px) {
        margin: 25px auto 0!important;
    }
}

form{
    max-width: 1040px;

}

.img {
    display: flex;
    height: 100%;
    align-items: center;
    img{
        max-width: 307px;
        width: 100%;
        height: auto;
    }
}

.text {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.14px;
    color: #fff;
}
.input-field {
    margin-bottom: 20px;
}

.phone-column{
    display: flex;
    align-items: center;
    .form-input{
        width: 100%;
    }
    &.deputy-phone-column{
        .col{
            flex: 1 1 auto;
        }
    }
    .input{
        display: flex;
        align-items: center;
    }
    input{
        margin: 0 20px;
        width: 20px;
        height: 20px;
    }
    label{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #1a2744;
    }
}

.form-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}

.form-group {
    width: 45%;
    justify-content: center;
}

.content-top {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    border: 0;
    @media all and(max-width: 950px) {
        margin: 40px 0;
    }
    @media all and(max-width: 600px) {
        display: block;
        text-align: center;
        .avatar{
            margin: 0 auto 20px;
        }
    }
}
.registartion-form_field{
    margin-bottom: 20px;
}

.title {
    font: 500 24px/1.33 @secondFontFamily;
    letter-spacing: -0.17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 40px;
    color: @blueColor;
    @media all and(max-width: 600px) {
        margin-right: 0;
        overflow: visible;
        white-space: normal;
    }
}

.logout {
    text-align: center;
    .logout-btn {
        color: @blueColor;
        font: 600 18px/1.33 @secondFontFamily;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        &:before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 26px;
            margin-right: 20px;
            background: url(../assets/images/logout.svg) no-repeat center center;
            background-size: contain;
        }
    }
}

</style>

<style lang="less" scoped>

.avatar{
    margin-right: 40px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #ffc900;
        transition: opacity .15s linear;
        display: block;
        position: relative;
        z-index: 2;
        object-fit: cover;
    }
    label{
        cursor: pointer;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: 0!important;
        &:hover{
            img{
                opacity: .4;
            }
            &:after, &:before{
                opacity: 1;
            }
        }
        &:after, &:before{
            content: '';
            display: block;
            position: absolute;
            transition: opacity .15s linear;
        }
        &:before{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 50%;
            z-index: 2;
            border: 2px dotted #CDD4E0;
        }
        &:after{
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #F5F5F5 url(../assets/images/camera.svg) center center no-repeat;
            background-size: 26px 26px;
        }
    }
}
.radio-field, .form-field, .radio-group {
    display: flex;
}

.registartion {
    .wrapper {
        display: flex;
        overflow-y: auto;
        max-width: 100%;
    }
    &-inner {
        width: 30%;
        height: 100vh;
        background: url(../assets/images/calendar-bg.jpg) no-repeat center center;
        background-size: cover;
        padding: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }

    &-title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.392px;
        color: #fff;
        margin-bottom: 144px;
    }

    &-img {
        width: 241px;
        height: 261px;
        margin-bottom: 29px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-description {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.14px;
        color: #fff;
        margin-bottom: 20px;
    }

    &-form {
        max-width: 1040px;
        margin: 0 auto;
        &_inner {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                display: block;
            }
        }
        .col{
            position: relative;
        }
        &_group {
            width: calc(50% - 40px);
            @media all and(max-width: 1200px) {
                width: calc(50% - 20px);
            }
            @media all and(max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }

        button {
            margin: 0 auto;
            display: block;
        }
    }
}

// Header

.header {
    width: 100%;
}

.header-logo {
    // width: 253px;
    height: 51px;
    margin-bottom: 22px;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}

/* breadcrumb */
.breadcrumb {
    list-style: none;
    margin-bottom: 45px;
}

.breadcrumb-item {
    display: inline;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: #fff;
    margin-right: 20px;
}

.breadcrumb-link {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: @yellowColor;
    text-decoration: none;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 15px;
        background: #fff;
        bottom: 1px;
        right: -11px;
    }
}

.btn-back {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFC900;
    text-decoration: none;

    &_icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #ffc900;
        box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
}

.d-flex {
    display: flex;
    justify-content: space-between;
    @media all and(max-width: 1350px) {
        display: block;
    }
}

.col {
    width: 48%;
    @media all and(max-width: 1350px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.avatar label {
    padding-left: 0;
}



@media screen and (max-width: 1920px) {
    .registartion {
        &-inner {
            max-width: 640px;
            width: 30%;
        }

        &-content {
            /* max-width: 1280px;*/
            width: 70%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .registartion {
        &-inner {
            padding: 40px 40px 40px 50px;
        }

        &-title {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 40px;
        }

        &-description {
            font-size: 18px;
            line-height: 26px;
        }

        &-content {
            padding: 40px;
        }

        &-form_inner {
            margin-bottom: 30px;
        }
    }

    .breadcrumb {
        margin-bottom: 30px;
    }
}
.achievement-form{
    max-width: none!important;
    background-color: white;
    border-radius: 10px;
    padding: 40px 50px;

    @media screen and (max-width: 1200px) {
        padding: 40px
    }
}
@media screen and (max-width: 1200px) {
    .registartion-img {
        width: 140px;
        height: 140px;
    }

    .registartion-description {
        font-size: 14px;
        line-height: 18px;
    }

    .btn-back {
        font-size: 16px;
        line-height: 24px;
    }

    .btn-back_icon {
        width: 40px;
        height: 40px;

        img {
            width: 10px;
            height: 18px;
        }
    }

    .registartion-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}

@media screen and (max-height: 700px) {
    .registartion-img {
        width: 140px;
        height: 140px;
    }

    .registartion-description {
        font-size: 14px;
        line-height: 18px;
    }

    .btn-back {
        font-size: 16px;
        line-height: 24px;
    }

    .btn-back_icon {
        width: 40px;
        height: 40px;

        img {
            width: 10px;
            height: 18px;
        }
    }

    .registartion-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}

.participant-container{
    .content-top{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .tabs-container{
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px){
            flex-direction: column;
        }
        .achievement-btn{
            margin: 0;
        }
        .blue-btn{
            .tab-item{
                background-color: #1a2744;
            }
        }
        .tab-item{
            height: 50px;
            width: 260px;
            border-radius: 10px;
            box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
            background-color: #ffc900;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 18px;
            font-family: @secondFontFamily;
            margin-right: 10px;
            @media (max-width: 1300px){
                width: auto;
                padding: 10px;
            }
            @media (max-width: 768px){
                width: 80vw;
                padding: 10px;
                margin: 5px;
            }
        }
    }
}
::v-deep{
    .common-block{
        @media (max-width: 950px){
            min-height: auto!important;
        }
        .title{
            display: none;
        }
        .description{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.13px;
            color: #fff;
            font-family: @defaultFontFamily;
            margin-bottom: 22px;
            margin-top: -20px;
        }
    }

}

</style>

<style lang="less">

.registartion-form .btn-group {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @media screen and (max-width: 480px) {
        justify-content: space-between;
    }
    button{
        margin: 0 5px!important;
        @media screen and (max-width: 992px) {
            max-width: 180px;
        }
        @media screen and (max-width: 375px) {
            max-width: 44%;
        }
    }
    .cancel-btn{
        svg{
            transform: scaleX(1.2) rotate(180deg);
            g{
                fill: #cdd4e0;
            }
        }
        &:after{
            box-shadow: 0 24px 24px -14px #cdd4e080;
        }
    }
}

</style>

<style lang="less" scoped>
    .profile {
        display: flex;

        @media all and(max-width: 992px) {
            display: block;
        }

        .content{
            padding-top: 82px;
            @media all and (max-width: 950px) {
                padding-top: 0;
                overflow: hidden;
            }
        }

        .common-block {
            max-height: 100vh;

            .title{
                margin-top: 60px;
                margin: 40px 0 80px;
            }

            @media all and(max-width: 992px) {
                .title{
                    margin: 10px 0;
                }
                .img{
                    display: none;
                }
            }
            img{
                height: auto;
            }
        }

        .to-cabinet {
            display: none !important;
        }
    }
</style>
