<template>
    <div class="achievement" v-if="isLoaded">
        <div class="top">
            <div class="item" v-if="achievementList.length" v-for="achievement in achievementList">
                <div class="title">Я — активний учасник партійних заходів</div>
                <div class="container">
                    <div class="achievement-list">
                        <div class="achievement-img" v-if="achievement">
                            <span v-if="achievement.type === 1">
                                <img src="../assets/images/bee_1.svg"  :alt="achievement.name">
                                <img src="../assets/images/bee_empty.svg" alt="">
                                <img src="../assets/images/bee_empty.svg" alt="">
                                <img src="../assets/images/bee_empty.svg" alt="">
                            </span>
                            <span v-if="achievement.type === 2">
                                <img src="../assets/images/bee_1.svg"  :alt="achievement.name">
                                <img src="../assets/images/bee_2.svg"  :alt="achievement.name">
                                <img src="../assets/images/bee_empty.svg" alt="">
                                <img src="../assets/images/bee_empty.svg" alt="">
                            </span>
                            <span v-if="achievement.type === 3">
                                <img src="../assets/images/bee_1.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_2.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_3.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_empty.svg" alt="">
                            </span>
                            <span v-if="achievement.type === 4">
                                <img src="../assets/images/bee_1.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_2.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_3.svg" :alt="achievement.name">
                                <img src="../assets/images/bee_4.svg" :alt="achievement.name">
                            </span>
                        </div>
                        <div class="achievement-img" v-else>
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                        </div>

                    </div>

                </div>
            </div>
            <div class="item" v-if="!achievementList.length">
                <div class="title">Я — активний учасник партійних заходів</div>
                <div class="container">
                    <div class="achievement-list">
                        <div class="achievement-img">
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                            <img src="../assets/images/bee_empty.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="friends">
                <div class="title">
                    Залучено друзів
                </div>
                <div class="counter">
                    <div class="icon">
                        <span class="material-icons">person</span>
                    </div>
                    <div class="number">{{ detailUser.invited }}</div>
                </div>
            </div>
        </div>
        <div class="projects">
            <div class="title">
                Мої проекти
            </div>
            <div class="projects-list">
                <div class="item">
                    <div class="circle"></div>
                    <div class="description">
                        <div class="text">Подано з моєї ініціативи</div>
                        <div class="number">{{ +detailUser.projects['created'] }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="circle green"></div>
                    <div class="description">
                        <div class="text">Залучення за ініціативи іншого партійця</div>
                        <div class="number">{{ +detailUser.projects['co-create'] }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="circle purple"></div>
                    <div class="description">
                        <div class="text">Поставлено на голосування</div>
                        <div class="number">{{ +detailUser.projects['in_progress'] }}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="circle blue"></div>
                    <div class="description">
                        <div class="text">Прийнято</div>
                        <div class="number">{{ +detailUser.projects['accepted'] }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="diagram">
            <div class="title">
                Моя медійна діяльність (модуль в стані розробки)
            </div>
            <img src="../assets/images/345.jpg" alt="">
        </div>
    </div>
    <p style="text-align: center" v-else>Завантаження...</p>
</template>

<script>
import InviteService from "@/services/InviteService";
import UserService from "@/services/UserService";

export default {
    name: "Achievement",
    props: ['awards'],
    data() {
        return {
            achievementList: [],
            userId: null,
            detailUser: null,
            isLoaded: false
        }
    },
    methods:{
        clickBack(){
            this.$router.go(-1);
        },
        async getDetailUser(id){
            let resp = await UserService.getParticipantsById(+id).catch(this.handleError);
            this.detailUser = resp.data;
            this.achievementList = resp.data.user.awards;
            this.isLoaded = true
        },
    },
    async mounted() {
        this.userId = await InviteService.getUserId().catch(this.handleError)
        this.getDetailUser(this.userId)
    },
}
</script>

<style scoped lang="scss">
.achievement{
    //max-width: 810px;
    margin: 0 auto;
    padding-bottom: 20px;
    margin-top: 24px;
    width: 100%;
    .diagram{
        .title{
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            color: #1a2744;
            margin-bottom: 30px;
            font-family: 'Fira Sans', sans-serif;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .projects{
        .title{
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.17px;
            color: #1a2744;
            font-family: 'Fira Sans', sans-serif;
            margin-bottom: 30px;
        }
        .projects-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item{
                width: 265px;
                height: 120px;
                border-radius: 10px;
                border: solid 1px #f5f5f5;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                .circle{
                    width: 60px;
                    height: 60px;
                    margin: 0 20px 0 0;
                    border-radius: 30px;
                    border: solid 1px #f5f5f5;
                    background-color: #dfc365;
                }
                .circle.green{
                    background-color: #3dea99;
                }
                .circle.purple{
                    background-color: #b601d9;
                }
                .circle.blue{
                    background-color: #18b4f8;
                }
                .description{
                    width: calc(100% - 80px);
                    .text, .number{
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: #1a2744;
                    }
                    .number{
                        font-size: 32px;
                        font-weight: bold;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
    .top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
        .title{
            font-size: 24px;
            font-weight: 500;
            color: #1a2744;
            font-family: 'Fira Sans', sans-serif;
            margin-bottom: 30px;
        }
        .counter{
            display: flex;
            .icon{
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 52px;
                height: 52px;
                background-color: #ffc900;
                border-radius: 50%;
            }
            .number{
                margin-left: 20px;
                font-size: 56px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #1a2744;
                font-family: 'Fira Sans', sans-serif;
            }

        }
    }
    .item{
        width: 77%;
        margin-bottom: 60px;

        @media screen and (max-width: 1360px) {
            width: 100%;            
        }

    }
    .container{

    }
}
.achievement-img{
    height: 50px;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    background: url(../assets/images/points-achievement.svg)repeat-x center center;

    @media screen and (max-width: 575px) {
        height: 30px;
    }

    span{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
.achievement-list{
    padding: 0 4px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;

    @media screen and (max-width: 575px) {
        height: 30px;
    }
}
</style>
